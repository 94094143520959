@charset "UTF-8";
html {
  margin: 0;
  overscroll-behavior: none;
}
body {
  margin: 0 auto;
  padding: 0;
  max-width: 900px;
}
h1,
h2,
h3 {
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
}
p,
pre {
  margin: 8px 0;
  padding: 0 8%;
  width: 84%;
}
a {
  margin: 0;
  padding: 0;
  transition: 1s;
  width: auto;
}
nav {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  display: block;
  margin: 0;
  padding: 0;
}
.directory {
  display: block;
}
nav a {
  padding: 0 2px;
}
section {
  float: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
hr {
  padding: 3px 0;
  margin: 40px 8% 30px 8%;
  border: none;
  border-top: 5px dashed black;
  box-sizing: border-box;
  width: 84%;
}
form {
  margin: 10px 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
button {
  border: none;
  cursor: pointer;
  margin: 10px 0;
  padding: 0;
  width: 100%;
}
input {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  padding: 0;
  text-align: left;
  width: 100%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
footer {
  margin: 0;
  padding: 10px 0;
}
footer p {
  margin: 0;
  padding: 0;
  width: 100%;
}
img {
  margin: 0;
  max-width: 100%;
  padding: 0;
  vertical-align: middle;
}
p span span {
  font-style: normal;
}
span {
  font-style: italic;
}
u {
  width: 100%;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
}
/* CUSTOM */
.centeredp {
  text-align: center;
}
.horizontalnav {
  background-color: #dff8e9;
}
.horizontalnav li {
  display: inline-block;
  padding: 0 10px;
}
.breakhere {
  display: block;
}
/* RESPONSIVITY */
@media only screen and (min-width: 1000px) {
  body {
    margin: 0;
    padding: 50px 0 0 0;
  }
  h1 {
    text-align: left;
  }
  nav {
    text-align: left;
  }
  nav a {
    padding: 0 4px 0 0;
  }
  p {
    padding: 0;
    width: 100%;
  }
  hr {
    margin: 60px 30% 50px 30%;
    width: 40%;
  }
  .horizontalnav {
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    text-align: center;
  }
  .breakhere {
    display: none;
  }
}
