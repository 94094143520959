@charset "UTF-8";
/* COLORS */
body {
  background-color: #fffffd;
}
h1,
h2,
h3 {
  color: #110405;
}
p,
pre {
  color: #110405;
}
p::selection {
  background: #95c9aa;
}
a {
  color: #51cc84;
}
a:hover {
  color: #000000;
}
u {
  color: #317e51;
}
button {
  background-color: #180d0e;
  color: #f1e1e3;
}
form {
  color: #180d0e;
}
input {
  background-color: #180d0e;
  color: #fffffd;
}
.header {
  background-color: #fffffd;
}
::-webkit-scrollbar-track {
  background-color: #110405;
}
::-webkit-scrollbar-thumb {
  background: #ffffff;
}
.selectedlink {
  color: #110405;
}
.notselectedlink {
  color: #51cc84;
}
/* FONTS */
@font-face {
  font-family: "Lora";
  src: local("Lora"), url("Lora-VariableFont_wght.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "LoraItalic";
  src: local("Lora"), url("Lora-Italic-VariableFont_wght.ttf");
  font-weight: normal;
}
h1,
h2,
h3 {
  font-family: Lora, serif;
  font-style: oblique;
  font-variant: normal;
  font-weight: 700;
  line-height: 50px;
}
h1 {
  font-size: 20px;
}
h2 {
  font-size: 16px;
}
h3 {
  font-size: 14px;
}
p,
a {
  font-family: Lora, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 30px;
  text-decoration: none;
}
li a {
  line-height: 15px;
}
u {
  font-family: Lora, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  text-decoration: none;
}
button {
  font-family: LoraItalic, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
form {
  font-family: LoraItalic, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
input {
  font-family: LoraItalic, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
@media only screen and (min-width: 700px) {
  h1 {
    font-size: 30px;
    line-height: 80px;
  }
  p,
  a {
    font-size: 15px;
  }
  button {
    font-size: 15px;
  }
  input {
    font-size: 15px;
  }
  u {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1000px) {
  h1 {
    font-size: 32px;
  }
}
@media only screen and (min-width: 1100px) {
  h1 {
    font-size: 35px;
  }
}
