.aboutleft {
  position: relative;
  z-index: -2;
}
.aboutright {
  position: relative;
  z-index: 0;
}
.aboutleft img {
  border-radius: 50%;
  margin: 0 5%;
  width: 90%;
  user-select: none;
  z-index: -2;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.aboutright p {
  text-align: center;
}
@media only screen and (min-width: 1200px) {
  .aboutright {
    background-color: #fffffd;
    border-color: #110405;
    border-style: solid;
  }
}
@media screen and (min-width: 360px) {
  .aboutleft {
    float: left;
    position: inherit;
    width: 30%;
  }
  .aboutleft img {
    clip-path: ellipse(200px 200px at -18% 150px);
    border-radius: 0;
    margin: 0;
    object-fit: scale-down;
    object-position: -130px;
    position: absolute;
    width: 100%;
  }

  .aboutright {
    float: right;
    padding: 50px 0 0 0;
    position: inherit;
    width: 70%;
  }
  .aboutright p {
    text-align: left;
  }
}
@media screen and (min-width: 400px) {
  .aboutleft img {
    object-position: -200px;
  }
}
@media screen and (min-width: 500px) {
  .aboutleft img {
    clip-path: ellipse(200px 200px at -5% 150px);
    object-position: -240px;
  }
}
@media screen and (min-width: 600px) {
  .aboutleft img {
    clip-path: ellipse(200px 200px at 0 150px);
    object-position: -270px -100px;
  }
}
@media screen and (min-width: 700px) {
  .aboutright {
    padding: 75px 0 0 0;
  }
  .aboutleft img {
    clip-path: ellipse(200px 200px at 5% 160px);
    object-position: -350px -100px;
  }
}
@media screen and (min-width: 800px) {
  .aboutleft img {
    clip-path: ellipse(200px 200px at 10% 160px);
    object-position: -390px -100px;
  }
}
@media only screen and (min-width: 900px) {
  .aboutleft {
    width: 40%;
  }
  .aboutleft img {
    border-radius: 50%;
    float: right;
    clip-path: none;
    position: unset;
    object-position: unset;
    width: 90%;
  }
  .aboutright {
    float: right;
    width: 60%;
  }
  .aboutright p {
    padding-left: 20px;
    text-align: left;
  }
}
@media only screen and (min-width: 1000px) {
  .aboutleft {
    float: none;
    position: relative;
    width: 74%;
  }
  .aboutleft img {
    border-radius: 50%;
    float: none;
    clip-path: none;
    margin: 0;
    position: relative;
    object-position: 0;
    width: 100%;
  }
  .aboutright {
    float: none;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .aboutright p {
    padding: 0;
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) {
  .aboutleft {
    width: 100%;
  }
  .aboutright {
    padding: 8px;
    position: absolute;
    border-radius: 5px;
    left: 450px;
    top: 350px;
    width: 400px;
  }
}
@media only screen and (min-width: 1400px) {
  .aboutright {
    top: 380px;
  }
}
