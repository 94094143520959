.header {
  margin: 0;
  padding: 0 0 25px 0;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 1000px) {
  .header {
    padding: 0;
    position: fixed;
    left: 150px;
    width: 300px;
  }
}
