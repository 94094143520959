.vince {
  font-size: 11px;
}
@media only screen and (min-width: 900px) {
  .vince {
    font-size: 14px;
  }
  .mainbody,
  .footerbody {
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 1000px) {
  .mainbody {
    margin: 50px 0 0 500px;
    width: 400px;
    padding: 0;
  }
  .footerbody {
    margin: 0 0 0 500px;
    width: 400px;
    padding: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .mainbody,
  .footerbody {
    width: 500px;
  }
}
@media only screen and (min-width: 1400px) {
  .mainbody,
  .footerbody {
    width: 600px;
  }
}
