.photosbody {
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 1000px) {
  .photosbody {
    width: 970px;
  }
  .Pipeline {
    max-width: 500px;
    padding: 5px;
  }
  .Palms {
    max-width: 260px;
    padding: 5px 10px 8px 13px;
  }
  .Rincon {
    max-width: 300px;
    padding: 5px;
  }
  .Friends {
    max-width: 400px;
    padding: 15px;
  }
  .Makaha {
    max-width: 330px;
    padding: 20px 10px 22px 12px;
  }
  .Yosemite {
    max-width: 500px;
    padding: 10px 20px 10px 18px;
  }
  .Tidepool {
    max-width: 500px;
    padding: 16px 10px 5px 15px;
  }
  .Surfing {
    max-width: 400px;
    padding: 20px;
  }
  .Firework {
    max-width: 380px;
    padding: 20px;
  }
  .Sunset {
    max-width: 480px;
    padding: 20px;
  }
  .Cloud {
    max-width: 200px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1200px) {
  .photosbody {
    width: 1150px;
  }
}
@media only screen and (min-width: 1400px) {
  .photosbody {
    width: 1370px;
  }
}
@media only screen and (min-width: 1600px) {
  .photosbody {
    width: 1570px;
  }
}
@media only screen and (min-width: 1700px) {
  .photosbody {
    width: 1670px;
  }
}
@media only screen and (min-width: 1800px) {
  .photosbody {
    width: 1770px;
  }
}
